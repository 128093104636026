import React from "react";
import { Modal, Typography, Divider } from "antd";

const { Paragraph, Title, Text } = Typography;

export default function InfoModal({ visible, onClose }) {
    return (
        <Modal
            title="Informations"
            open={visible}
            onCancel={onClose}
            footer={null}
        >
            <Typography>
                <Title level={4}>Détails</Title>

                <Paragraph>
                    <Text>
                        Pour acheter nos produits ou obtenir plus d'informations, veuillez nous contacter via l'un des moyens ci-dessus. Nous serons ravis de répondre à vos questions !
                    </Text>
                </Paragraph>
                <Divider />
                <Paragraph>
                    <strong>Numéro de téléphone :</strong> 06 64 20 32 48
                </Paragraph>
                <Paragraph>
                    <strong>Email :</strong> contact@devsempire.ma
                </Paragraph>



            </Typography>
        </Modal>
    );
}
