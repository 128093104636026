import React, { useState } from "react";
import ModalForm from "./ModalForm"; // Import du composant ModalForm

export default function Pricing() {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const hideModal = () => {
        setIsModalVisible(false);
    };

    return (
        <section className="relative md:py-24 py-16 bg-slate-50 dark:bg-slate-800" id="pricing">
            <div className="container relative">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="font-semibold text-2xl leading-normal mb-4">Des Tarifs Abordables et Adaptés à Vos Besoins</h3>
                    <p className="text-slate-400 max-w-xl mx-auto">
                        Explorez nos plans et choisissez celui qui convient le mieux à la taille et aux besoins de votre entreprise au Maroc.
                        Tous nos abonnements incluent un accès complet aux fonctionnalités,
                        un support client dédié, et des mises à jour régulières.
                    </p>
                </div>

                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                    {/* Carte Gratuit */}
                    <div className="group p-6 relative overflow-hidden shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 rounded-md h-fit">
                        <h6 className="font-semibold mb-5 text-xl">Gratuit</h6>
                        <div className="flex mb-5">
                            
                            <span className="price text-5xl h6 font-semibold mb-0">0</span>
                            <span className="text-lg font-medium">DH</span>
                            <span className="text-lg font-medium self-end mb-1">/Mois</span>
    
                        </div>
                        <ul className="list-none text-slate-400">
                            <li className="mb-1 flex"><i className="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>Nous offrons un mois gratuit de service pour les nouveaux clients.</span></li>
                        </ul>
                        <button
                            className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-teal-500/5 hover:bg-teal-500 text-teal-500 hover:text-white w-full mt-5"
                            onClick={showModal}
                        >
                            Démo gratuite
                        </button>
                    </div>

                    {/* Carte Individuel */}
                    <div className="group p-6 relative overflow-hidden shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 rounded-md h-fit">
                        <h6 className="font-semibold mb-5 text-xl">Individuel</h6>
                        <div className="flex mb-5">
                            
                            <span className="price text-5xl h6 font-semibold mb-0">147</span>
                            <span className="text-lg font-medium">DH</span>
                            <span className="text-lg font-medium self-end mb-1">/Mois</span>

                        </div>
                        <ul className="list-none text-slate-400">
                            <li className="mb-1 flex"><i className="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>Nous offrons 7 jours gratuits pour les nouveaux clients.</span></li>
                            <li className="mb-1 flex"><i className="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>Agence de marketing talentueuse et expérimentée</span></li>
                        </ul>
                        <button
                            className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-teal-500/5 hover:bg-teal-500 text-teal-500 hover:text-white w-full mt-5"
                            onClick={showModal}
                        >
                          Acheter maintenant
                        </button>
                    </div>

                    {/* Carte Professionnel */}
                    <div className="group relative overflow-hidden shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 rounded-md h-fit">
                        <div className="bg-gradient-to-tr from-teal-500 to-teal-700 text-white py-2 px-6 h6 text-lg font-medium">
                            Populaire
                        </div>
                        <div className="p-6">
                            <h6 className="font-semibold mb-5 text-xl">Professionnel</h6>
                            <div className="flex mb-5">
                               
                                <span className="price text-5xl h6 font-semibold mb-0">290</span>
                                <span className="text-lg font-medium">DH</span>
                                <span className="text-lg font-medium self-end mb-1">/Mois</span>

                            </div>
                            
                            <ul className="list-none text-slate-400">
                                <li className="mb-1 flex"><i className="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>Nous offrons 14 jours gratuits pour les nouveaux clients.</span></li>
                                <li className="mb-1 flex"><i className="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>Accès complet à toutes les fonctionnalités</span></li>
                                <li className="mb-1 flex"><i className="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>Fichiers sources inclus</span></li>
                                <li className="mb-1 flex"><i className="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>Rendez-vous gratuits</span></li>
                                <li className="mb-1 flex"><i className="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>Sécurité renforcée</span></li>
                                <li className="mb-1 flex"><i className="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>Installation gratuite</span></li>
                            </ul>
                            <button
                                className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-teal-500 text-white w-full mt-5"
                                onClick={showModal}
                            >
                                Acheter maintenant
                            </button>
                        </div>
                    </div>

                    {/* Carte Personnalisé */}
                    <div className="group p-[1px] relative overflow-hidden shadow dark:shadow-gray-700 rounded-md bg-gradient-to-tr from-teal-500 to-teal-700 h-fit">
                        <div className="p-6 bg-white dark:bg-slate-900 rounded-md">
                            <h6 className="font-semibold mb-5 text-xl">Personnalisé</h6>
                            <p className="text-slate-400 mb-5">Le plan tarifaire sera défini selon les besoins du client ou de l'entreprise.</p>
                            
                            
                            <ul className="list-none">
                                <li className="mb-1 font-medium flex"><i className="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>Tarification personnalisée</span></li>
                            </ul>
                            <button
                                className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-teal-500/5 hover:bg-teal-500 text-teal-500 hover:text-white w-full mt-5"
                                onClick={showModal}
                            >
                                Contactez-nous
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            <ModalForm visible={isModalVisible} onClose={hideModal} />
        </section>
    );
}
