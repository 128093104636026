import { FiTrendingUp, FiDollarSign, FiCompass,FiCommand, FiBox, FiCamera} from '../assets/icons/vander'
import client1 from '../assets/images/team/01.jpg'
import client2 from '../assets/images/team/02.jpg'
import client3 from '../assets/images/team/03.jpg'


import blog1 from '../assets/images/blog/01.png'
import blog2 from '../assets/images/blog/02.png'
import blog3 from '../assets/images/blog/03.png'

import company1 from '../assets/images/team/amazon.svg'
import company2 from '../assets/images/team/google.svg'
import company3 from '../assets/images/team/lenovo.svg'
import company4 from '../assets/images/team/paypal.svg'
import company5 from '../assets/images/team/shopify.svg'
import company6 from '../assets/images/team/spotify.svg'

export const servicesData = [
    {
        icon:FiTrendingUp,
        title:'Tableau de Bord Centralisé',
        desc:'Obtenez une vue d’ensemble en temps réel de vos stocks, commandes, factures et paiements avec un tableau de bord intuitif.'
    },
    {
        icon:FiDollarSign,
        title:'Suivi des Stocks et Gestion des Alertes',
        desc:'Soyez informé automatiquement des niveaux de stock critiques et recevez des suggestions de réapprovisionnement basées sur vos ventes et vos cycles de demande.'
    },
    {
        icon:FiCompass,
        title:'Gestion des Fournisseurs et des Clients',
        desc:"Gérez facilement vos contacts, suivez vos transactions et consultez l'historique complet des achats et ventes pour chaque client et fournisseur."
    },
    {
        icon:FiCommand,
        title:'Création et Personnalisation de Factures',
        desc:'Génération rapide de factures avec personnalisation de logo, choix de devises et options de paiement adaptées à vos clients.'
    },
    {
        icon:FiBox,
        title:'Rapports d’Analyse Précis',
        desc:'Accédez à des rapports détaillés sur vos ventes, marges, inventaire, et comptes clients pour une prise de décision éclairée.'
    },
    {
        icon:FiCamera,
        title:'Sécurité Avancée et Sauvegardes',
        desc:'Gardez vos données en sécurité grâce à un système de cryptage avancé et des sauvegardes régulières, assurant la confidentialité et l’intégrité de vos informations.'
    },
]

export const clientData = [
    {
        image:client2,
        name:'Sarah M',
        title:' Responsable d’Opérations',
        desc:'" Le logiciel de gestion de stock de Notre service a permis de réduire les erreurs de facturation et d’améliorer l’organisation de notre inventaire ! "'
    },
    {
        image:client1,
        name:'Omar L',
        title:'Fondateur d’une PME',
        desc:'" Avec Notre service, je gère mes stocks et factures en un clin d\'œil. Un véritable gain de temps pour notre équipe ! "'
    },
    {
        image:client3,
        name:'Fatima Z.',
        title:'Directrice Finances',
        desc:'"Les rapports de vente sont incroyablement utiles pour analyser nos performances et ajuster nos stratégies."'
    },
]

export const blogData = [
    {
        image:blog1,
        title:'Comment Optimiser la Gestion de Stock pour Réduire les Coûts',
        desc:'Apprenez à optimiser la gestion de vos stocks pour réduire les coûts grâce à des stratégies comme l’analyse des ventes, l’automatisation des processus et une gestion intelligente des inventaires.',
        date:'16/11/2024'
    },
    {
        image:blog2,
        title:'5 Conseils pour une Facturation Plus Rapide et Efficace',
        desc:'Découvrez 5 conseils pratiques pour accélérer et améliorer votre processus de facturation, réduire les erreurs et gagner du temps, tout en optimisant la gestion financière de votre entreprise.',
        date:'8/11/2024'
    },
    {
        image:blog3,
        title:'Les Avantages d’une Solution de Gestion de Stock Automatisée pour les PME',
        desc:'Explorez les avantages d’une solution de gestion de stock automatisée pour les PME, incluant l\'optimisation des coûts, la réduction des erreurs et l\'amélioration de l\'efficacité opérationnelle.',
        date:'21/10/2024'
    },
]

export const companyLogo = [company1, company2, company3, company4, company5, company6]