import React, { useState } from "react";
import contact from '../assets/images/contact.svg';

export default function GetInTouch() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        comments: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async (e) => {
        // e.preventDefault();
        // try {
        //     const response = await fetch('http://localhost/Upcover_ReactJs/public/sendEmail.php', {
        //         method: 'POST',
        //         headers: { 'Content-Type': 'application/json' },
        //         body: JSON.stringify(formData),
        //     }
        //      );
        //      console.log(formData)
        //     const result = await response.json();
        //     alert(result.message);
        // } catch (error) {
        //     console.log(formData)
        //     console.error(error);
        //     alert('Erreur lors de l\'envoi du message.');
        // }
    };
    

    return (
        <section className="relative lg:py-24 py-16 bg-slate-50 dark:bg-slate-800" id="contact">
            <div className="container relative">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="font-semibold text-2xl leading-normal mb-4">Contactez-nous</h3>
                    <p className="text-slate-400 max-w-xl mx-auto">
                        Vous avez des questions ou souhaitez en savoir plus sur nos services ? N'hésitez pas à nous contacter, nous sommes là pour vous aider !
                    </p>
                </div>

                <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
                    <div className="lg:col-span-7 md:col-span-6">
                        <img src={contact} alt="Contact illustration" />
                    </div>

                    <div className="lg:col-span-5 md:col-span-6">
                        <div className="lg:ms-5">
                            <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                <form onSubmit={handleSubmit}>
                                    <div className="grid lg:grid-cols-12 grid-cols-1 gap-3">
                                        <div className="lg:col-span-6">
                                            <label htmlFor="name" className="font-semibold">Votre Nom :</label>
                                            <input
                                                name="name"
                                                id="name"
                                                type="text"
                                                className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                                                placeholder="Nom :"
                                                value={formData.name}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div className="lg:col-span-6">
                                            <label htmlFor="email" className="font-semibold">Votre Email :</label>
                                            <input
                                                name="email"
                                                id="email"
                                                type="email"
                                                className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                                                placeholder="Email :"
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div className="lg:col-span-12">
                                            <label htmlFor="subject" className="font-semibold">Objet de votre question :</label>
                                            <input
                                                name="subject"
                                                id="subject"
                                                className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                                                placeholder="Objet de votre question :"
                                                value={formData.subject}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div className="lg:col-span-12">
                                            <label htmlFor="comments" className="font-semibold">Votre Message :</label>
                                            <textarea
                                                name="comments"
                                                id="comments"
                                                className="mt-2 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                                                placeholder="Votre message :"
                                                value={formData.comments}
                                                onChange={handleChange}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <button
                                        type="submit"
                                        id="submit"
                                        name="send"
                                        className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-teal-500 text-white mt-2"
                                    >
                                        Envoyer le Message
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
