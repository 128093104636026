import React from "react";
import { Link } from "react-router-dom";
import aboutImage from '../assets/images/about3.jpg'

import CountUp from 'react-countup';

export default function About() {
    return (
        <section className="relative md:py-24 py-16" id="about">
            <div className="container relative">
                <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
                    <div className="md:col-span-6">
                        <div className="lg:me-8">
                            <div className="relative">
                                <img src={aboutImage} className="rounded-full shadow dark:shadow-gray-700" alt="" />

                                <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 mx-auto size-56 flex justify-center items-center bg-white dark:bg-slate-900 rounded-full shadow dark:shadow-gray-700">
                                    <div className="text-center">
                                        <span className="text-teal-500 text-2xl font-semibold mb-0 block"><CountUp className="counter-value text-6xl font-semibold" start={0} end={8} />+</span>
                                        <span className="font-semibold block mt-2">Années <br /> d'expérience</span>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="md:col-span-6">
                        <div className="lg:ms-8">
                           
                            <h2 className="font-semibold text-2xl leading-normal mb-4">Pourquoi Choisir Notre service ?</h2>
                            <h6 className="text-teal-500 text-sm font-semibold uppercase mb-2">
                                1- Optimisation de la Gestion de Stock
                            </h6>
                            <p className="text-slate-400 max-w-xl mb-6">
                                Maintenez un inventaire précis, optimisez vos niveaux de stock et évitez les ruptures grâce à des alertes intelligentes.
                            </p>
                            <h6 className="text-teal-500 text-sm font-semibold uppercase mb-2">
                                2- Facturation Rapide et Facile
                            </h6>
                            <p className="text-slate-400 max-w-xl mb-6">
                            Créez des factures professionnelles personnalisées en quelques clics, suivez les paiements et réduisez les retards de règlement.                            </p>
                            <h6 className="text-teal-500 text-sm font-semibold uppercase mb-2">
                                3- Visualisation des Données et Rapports en Temps Réel
                            </h6>
                            <p className="text-slate-400 max-w-xl mb-6">
                            Accédez à des rapports en temps réel pour suivre vos performances, surveiller les ventes et analyser vos marges bénéficiaires.                            </p>
                            <h6 className="text-teal-500 text-sm font-semibold uppercase mb-2">
                                4- Automatisation et Gain de Temps
                            </h6>
                            <p className="text-slate-400 max-w-xl mb-6">
                            Simplifiez la gestion de votre entreprise en automatisant les processus de commande, facturation et suivi des stocks, pour vous concentrer sur ce qui compte vraiment.                            </p>
                            


                            <Link to="" className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-teal-500 text-white">Découvrez Tous Nos Avantages<i className="mdi mdi-chevron-right align-middle ms-0.5"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}